import React, { FunctionComponent } from "react";
import { DeviceModel } from "./Types";

import {
    DataGrid,
    GridRowModes,
    GridRowModesModel,
    GridToolbarContainer,
    MuiEvent,
    GridRowId,
    GridRowModel,
    GridActionsCellItem,
    GridToolbarExport,
    GridColDef,
    GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Button, Tooltip } from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import { ChangeEvent } from "react";
import Papa from "papaparse";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { FilterModelCortege } from "./Types";

const StyledBox = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    "& .MuiDataGrid-cell--editing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
        "& .MuiInputBase-root": {
            height: "100%",
        },
    },
    "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
        color: theme.palette.error.main,
    },
}));

const configContext = React.createContext<string | undefined>(undefined);
const modelsContext = React.createContext<DeviceModel[]>([]);

type ConfigFilterModelsProps = {
    configId?: string;
    models: DeviceModel[];
    rows: FilterModelCortege[];
    onRowsChange: (newRows: FilterModelCortege[]) => void;
    loading?: boolean;
};

interface FilterModelsEditToolbarProps {
    rows: FilterModelCortege[] | any[];
    setRows: (newRows: (oldRows: FilterModelCortege[]) => FilterModelCortege[]) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

const FilterModelsEditToolbar: FunctionComponent<FilterModelsEditToolbarProps> = (props: FilterModelsEditToolbarProps) => {
    const { rows, setRows, setRowModesModel } = props;
    const configId = React.useContext(configContext);
    const models = React.useContext(modelsContext);

    const addClick = () => {
        const next_model = models.find((model) => !rows.map((row) => row.modelId).includes(model.id));
        if (!next_model) return;
        const dummy: FilterModelCortege = { id: randomId(), isNew: true, modelId: next_model.id, household: false };
        setRows((oldRows) => [...oldRows, dummy]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [dummy.id]: { mode: GridRowModes.Edit, fieldToFocus: "modelId" },
        }));
    };

    const purgeClick = () => {
        setRows((oldRows) => []);
    };

    const importClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: true,
                complete: function (results: Papa.ParseResult<any>) {
                    let newRows: FilterModelCortege[] = results.data
                        .map((line): FilterModelCortege | null => {
                            let model_alias = line['Model'];
                            let model = models.find((model) => model.alias === model_alias);
                            if (!model) return null;
                            let household: boolean = line['Household'] ? true : false;
                            return {
                                id: randomId(),
                                modelId: model.id,
                                household: household,
                            };
                        })
                        .filter((row): row is FilterModelCortege => row !== null);
                    const seenModels = new Set<number>();
                    const hasNoDuplicates = newRows.every((row) => {
                        const oldSize = seenModels.size;
                        seenModels.add(row.modelId);
                        return oldSize !== seenModels.size;
                    });
                    if (!hasNoDuplicates) {
                        alert("Duplicate models found in import file. Please check your file and try again.");
                        return;
                    }
                    setRows((oldRows) =>
                        oldRows.map((r1) => newRows.find((r2) => r2.modelId === r1.modelId) || r1).concat(newRows.filter((r2) => !oldRows.some((r1) => r1.modelId === r2.modelId)))
                    );
                },
            });
            event.target.value = "";
        }
    };

    return (
        <GridToolbarContainer>
            <Button startIcon={<AddIcon />} onClick={addClick}>
                Add
            </Button>
            <Button startIcon={<DeleteSweepIcon />} onClick={purgeClick}>
                Purge
            </Button>
            <Button startIcon={<FileUploadIcon />} component="label">
                Import
                <input hidden accept=".csv*" type="file" onChange={importClick} />
            </Button>
            <GridToolbarExport
                startIcon={<FileDownloadIcon />}
                printOptions={{ disableToolbarButton: true }}
                csvOptions={{
                    fileName: configId || "unsaved",
                    delimiter: ",",
                    utf8WithBom: true,
                }}
            />
        </GridToolbarContainer>
    );
};

export const ConfigFilterModels: FunctionComponent<ConfigFilterModelsProps> = (props: ConfigFilterModelsProps) => {
    const { configId, models, rows, onRowsChange, loading } = props;
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        onRowsChange(rows.filter((row) => row.id !== id) || []);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            onRowsChange(rows.filter((row) => row.id !== id) || []);
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow: any = { ...newRow, isNew: false };
        onRowsChange(rows.map((row) => (row.id === newRow.id ? updatedRow : row)) || []);
        return updatedRow;
    };

    const processRowUpdateError = (error: any) => {
        console.log(error);
    };

    const configProjectionsColumns: GridColDef[] = [
        {
            field: "modelId",
            headerName: "Model",
            editable: true,
            sortable: true,
            flex: 2,
            type: "singleSelect",
            valueOptions: models.map((model) => ({ label: model.alias, value: model.id })),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.hasChanged && rows.map((row) => row.modelId).filter((id) => id === params.props.value).length > 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "household",
            headerName: "Household",
            editable: true,
            sortable: true,
            flex: 1,
            type: "boolean",
            valueFormatter: (item) => item.value ? "1" : "0",
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <Tooltip title="Save">
                            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />
                        </Tooltip>,
                        <Tooltip title="Cancel">
                            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />
                        </Tooltip>,
                    ];
                }
                return [
                    <Tooltip title="Edit">
                        <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />
                    </Tooltip>,
                    <Tooltip title="Delete">
                        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
                    </Tooltip>,
                ];
            },
        },
    ];

    return (
        <configContext.Provider value={configId}>
            <modelsContext.Provider value={models}>
                <StyledBox>
                    <DataGrid
                        loading={loading}
                        rows={rows || []}
                        columns={configProjectionsColumns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                        onRowEditStart={(_, event: MuiEvent) => {
                            event.defaultMuiPrevented = true;
                        }}
                        onRowEditStop={(_, event: MuiEvent) => {
                            event.defaultMuiPrevented = true;
                        }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={processRowUpdateError}
                        slots={{
                            toolbar: FilterModelsEditToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                rows: rows || [],
                                setRows: (updateRows: any) => onRowsChange(updateRows(rows)),
                                setRowModesModel,
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        autoPageSize
                    />
                </StyledBox>
            </modelsContext.Provider>
        </configContext.Provider>
    );
};
