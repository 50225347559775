import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { randomId } from "@mui/x-data-grid-generator";
import { DeviceModel, FwImage, Status, Type, Strategy, StreamConfig } from "./Types";
import {
  addStreamConfig as addConfigMutation,
  clearPartialRolloutWaitList as clearWaitListMutation,
  deleteStreamConfig as deleteConfigMutation,
  updateStreamConfig as updateConfigMutation,
} from "./graphql/mutations";
import {
  listExcludedDevices,
  listImages,
  listIncludedDevices,
  listModels,
  listPartialRolloutWaitListDevices,
  listStreamConfigs,
  checkStreamConfig,
} from "./graphql/queries";

export async function fetchConfigs(): Promise<StreamConfig[]> {
  const response = (await API.graphql({
    query: listStreamConfigs,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  const db_data: any[] = response.data.listStreamConfigs;
  const massaged_data: StreamConfig[] = db_data.map((line: any) => {
    // create empty fields for optionals fo UI can use them for controlled components
    line?.filters || (line.filters = {});
    line.filters?.models || (line.filters.models = {});
    line.filters.models?.included || (line.filters.models.included = []);
    line.filters.models?.excluded || (line.filters.models.excluded = []);
    line.filters?.devices || (line.filters.devices = {});
    line.filters.devices?.included || (line.filters.devices.included = []);
    line.filters.devices?.excluded || (line.filters.devices.excluded = []);
    line?.projections || (line.projections = []);
    line?.minimumVersion || (line.minimumVersion = "");
    line?.maximumVersion || (line.maximumVersion = "");
    // Keep track of the initial target percentage initially retrieved from the DB,
    // so that we can later calculate the inclusion rate correctly.
    line.rolloutTargetPercentagePrev = line?.rolloutTargetPercentage;
    line?.rolloutTargetPercentagePrev || (line.rolloutTargetPercentagePrev = 0);
    line?.rolloutTargetPercentage || (line.rolloutTargetPercentage = 100);
    line?.rolloutInclusionRate || (line.rolloutInclusionRate = 1);
    // massage existing fields for DataGrid
    line.filters.models.included = line.filters.models.included.map((fm: any) => ({
      id: randomId(),
      ...fm,
    }));
    line.filters.models.excluded = line.filters.models.excluded.map((fm: any) => ({
      id: randomId(),
      ...fm,
    }));
    line.filters.devices.included = line.filters.devices.included.map((fd: any) => ({
      id: randomId(),
      ...fd,
    }));
    line.filters.devices.excluded = line.filters.devices.excluded.map((fd: any) => ({
      id: randomId(),
      ...fd,
    }));
    line.projections = line.projections.map((proj: any) => ({
      id: randomId(),
      ...proj,
    }));
    return line;
  });
  console.log("fetched %s configs", response.data.listStreamConfigs.length);
  return massaged_data;
}

export async function fetchIncludedDevices(configId: string): Promise<String[]> {
  const response = (await API.graphql({
    query: listIncludedDevices,
    variables: { streamId: configId },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  const db_data = response.data.listIncludedDevices;
  if (db_data) {
    console.log("fetched %s devices included for config %s", db_data.length, configId);
    return db_data;
  }
  console.log("no devices included for config %s", configId);
  return [];
}

export async function fetchWaitingDevices(configId: string): Promise<String[]> {
  const response = (await API.graphql({
    query: listPartialRolloutWaitListDevices,
    variables: {
      streamId: configId,
    },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  const db_data = response.data.listPartialRolloutWaitListDevices;
  if (db_data) {
    console.log("fetched %s devices in wait list for config %s", db_data.length, configId);
    return db_data;
  }
  console.log("no devices in wait list for config %s", configId);
  return [];
}

export async function fetchExcludedDevices(configId: string): Promise<String[]> {
  const response = (await API.graphql({
    query: listExcludedDevices,
    variables: { streamId: configId },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  const db_data = response.data.listExcludedDevices;
  if (db_data) {
    console.log("fetched %s devices excluded for config %s", db_data.length, configId);
    return db_data;
  }
  console.log("no devices excluded for config %s", configId);
  return [];
}

export async function fetchModels(): Promise<DeviceModel[]> {
  const response = (await API.graphql({
    query: listModels,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as {
    data: any;
  };
  console.log("fetched %s models", response.data.listModels.length);
  return response.data.listModels;
}

export async function fetchImages(): Promise<FwImage[]> {
  const response = (await API.graphql({
    query: listImages,
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as {
    data: any;
  };
  console.log("fetched %s images", response.data.listImages.length);
  return response.data.listImages;
}

export const mintConfig: StreamConfig = {
  uid: "",
  name: "",
  description: "",
  priority: 1,
  type: Type.REGULAR,
  status: Status.ACTIVE,
  strategy: Strategy.UP_ONLY,
  force: false,
  filters: {
    models: {
      included: [],
      excluded: [],
    },
    devices: {
      included: [],
      excluded: [],
    },
  },
  projections: [],
  minimumVersion: "",
  maximumVersion: "",
  rolloutTargetPercentage: 100,
  // For a new config, we need to treat it like it hasn't been rolled out
  // to any devices yet, so that the initial inclusion rate calculation
  // will be correct
  rolloutTargetPercentagePrev: 0,
  rolloutInclusionRate: 1,
};

function removeGraphQLTypename(obj: any): void {
  if (typeof obj !== "object" || obj === null) return;
  if ("__typename" in obj) {
    delete obj["__typename"];
  }
  for (let key in obj) {
    removeGraphQLTypename(obj[key]);
  }
}

function brushUpStreamConfig(victim: StreamConfig, images: FwImage[]): any {
  // deep copy done JS way (facepalm here)
  let data = JSON.parse(JSON.stringify(victim));
  // remove empty fields
  if (data.filters.models.included.length === 0) {
    delete data.filters.models.included;
  } else {
    data.filters.models.included = data.filters.models.included.map((fm: any) => {
      delete fm.id;
      delete fm.isNew;
      return fm;
    });
  }
  if (data.filters.models.excluded.length === 0) {
    delete data.filters.models.excluded;
  } else {
    data.filters.models.excluded = data.filters.models.excluded.map((fm: any) => {
      delete fm.id;
      delete fm.isNew;
      return fm;
    });
  }
  if (data.filters.devices.included.length === 0) {
    delete data.filters.devices.included;
  } else {
    data.filters.devices.included = data.filters.devices.included.map((fd: any) => {
      delete fd.id;
      delete fd.isNew;
      return fd;
    });
  }
  if (data.filters.devices.excluded.length === 0) {
    delete data.filters.devices.excluded;
  } else {
    data.filters.devices.excluded = data.filters.devices.excluded.map((fd: any) => {
      delete fd.id;
      delete fd.isNew;
      return fd;
    });
  }
  if (["included", "excluded"].every((f) => !(f in data.filters.models))) delete data.filters.models;
  if (["included", "excluded"].every((f) => !(f in data.filters.devices))) delete data.filters.devices;
  if (["models", "devices"].every((f) => !(f in data.filters))) delete data.filters;
  if (data.projections.length === 0) {
    delete data.projections;
  } else {
    data.projections = data.projections.map((proj: any) => {
      delete proj.id;
      delete proj.isNew;
      // version comes from image selected
      proj.version = images.find((image) => image.id === proj.imageId)?.version;
      proj.versionCutoff = proj.versionCutoff.trim();
      return proj;
    });
  }
  if (data.minimumVersion === "") delete data.minimumVersion;
  if (data.maximumVersion === "") delete data.maximumVersion;
  if (data.rolloutTargetPercentage === "") delete data.rolloutTargetPercentage;
  return data;
}

function finaliseRolloutChanges(data: StreamConfig): StreamConfig {
  let prev_target = data?.rolloutTargetPercentagePrev ?? 0;
  let new_target = data?.rolloutTargetPercentage ?? 100;
  let inclusion_rate = 1;
  if (prev_target < 100) {
    inclusion_rate = (new_target - prev_target) / (100 - prev_target);
  }
  data.rolloutInclusionRate = inclusion_rate;
  console.log(
    "calculated rollout inclusion rate %f for target percentage %f% -> %f%",
    inclusion_rate,
    prev_target,
    new_target,
  );
  return data;
}

export async function clearRolloutWaitList(data: StreamConfig): Promise<void> {
  let prev_target = data?.rolloutTargetPercentagePrev ?? 0;
  let new_target = data?.rolloutTargetPercentage ?? 100;
  if (new_target !== prev_target) {
    // We've updated the rollout, so give devices in the wait list a
    // chance to become enrolled
    if (data?.uid) {
      console.log("clearing wait list for stream %s [%s]", data.name, data.uid);
      await API.graphql({
        query: clearWaitListMutation,
        variables: { streamId: data.uid },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      console.log("wait list cleared for stream %s [%s]", data.name, data.uid);
    }
  } else {
    console.log("No rollout changes for stream  %s [%s], leaving wait list unchanged", data.name, data.uid);
  }
}

export async function addConfig(victim: StreamConfig, images: FwImage[]): Promise<void> {
  console.log("adding new config");
  let data = brushUpStreamConfig(victim, images);
  data = finaliseRolloutChanges(data);
  delete data.uid;
  removeGraphQLTypename(data);
  const newConfig = (await API.graphql({
    query: addConfigMutation,
    variables: { uid: victim.uid, input: data },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  console.log("config %s [%s] created", newConfig.data.addStreamConfig.name, newConfig.data.addStreamConfig.uid);
}

export async function updateConfig(victim: StreamConfig, images: FwImage[]): Promise<void> {
  console.log("updating config %s [%s]", victim.name, victim.uid);
  let data = brushUpStreamConfig(victim, images);
  data = finaliseRolloutChanges(data);
  delete data.uid;
  delete data.owner;
  delete data.createdAt;
  delete data.updatedAt;
  removeGraphQLTypename(data);
  const newConfig = (await API.graphql({
    query: updateConfigMutation,
    variables: { uid: victim.uid, input: data },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  console.log("config %s [%s] updated", newConfig.data.updateStreamConfig.name, newConfig.data.updateStreamConfig.uid);
  await clearRolloutWaitList(newConfig.data.updateStreamConfig);
}

export async function deleteConfig(configId: string): Promise<void> {
  await API.graphql({
    query: deleteConfigMutation,
    variables: { uid: configId },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  });
  console.log("config %s deleted", configId);
}

export async function checkConfig(victim: StreamConfig, images: FwImage[]): Promise<String[]> {
  console.log("checking config for errors");
  const uid = victim.uid;
  let data = brushUpStreamConfig(victim, images);
  data = finaliseRolloutChanges(data);
  delete data.uid;
  delete data.owner;
  delete data.createdAt;
  delete data.updatedAt;
  removeGraphQLTypename(data);
  console.log(data);
  const response = (await API.graphql({
    query: checkStreamConfig,
    variables: { streamId: uid, input: data },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as { data: any };
  const errors: String[] = response.data.checkStreamConfig;
  console.log("received %s errors", errors.length);
  return errors;
}
