import React, { FunctionComponent } from "react";
import { DeviceModel, FwImage } from "./Types";

import {
    DataGrid,
    GridRowModes,
    GridRowModesModel,
    GridToolbarContainer,
    MuiEvent,
    GridRowId,
    GridRowModel,
    GridActionsCellItem,
    GridToolbarExport,
    GridColDef,
    GridValueGetterParams,
    GridPreProcessEditCellProps,
    GridValueOptionsParams,
    useGridApiContext,
    GridEditSingleSelectCell,
    GridRenderEditCellParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Button } from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import { ChangeEvent } from "react";
import Papa from "papaparse";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ProjectionCortege } from "./Types";

const StyledBox = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    "& .MuiDataGrid-cell--editing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
        "& .MuiInputBase-root": {
            height: "100%",
        },
    },
    "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
        color: theme.palette.error.main,
    },
}));

const configContext = React.createContext<string | undefined>(undefined);
const modelsContext = React.createContext<DeviceModel[]>([]);
const imagesContext = React.createContext<FwImage[]>([]);

type ConfigProjectionsProps = {
    configId?: string;
    models: DeviceModel[];
    images: FwImage[];
    rows: ProjectionCortege[];
    onRowsChange: (newRows: ProjectionCortege[]) => void;
    loading?: boolean;
};

interface ProjectionsEditToolbarProps {
    rows: ProjectionCortege[];
    setRows: (newRows: (oldRows: ProjectionCortege[]) => ProjectionCortege[]) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

const ProjectionsEditToolbar: FunctionComponent<ProjectionsEditToolbarProps> = (props: ProjectionsEditToolbarProps) => {
    const { rows, setRows, setRowModesModel } = props;
    const configId = React.useContext(configContext);
    const models = React.useContext(modelsContext);
    const images = React.useContext(imagesContext);

    const addClick = () => {
        const next_model = models.find((model) => !rows.map((row) => row.modelId).includes(model.id));
        if (!next_model) return;
        const next_image = images.find((image) => image.hw === next_model.hw && image.dev === next_model.dev);
        if (!next_image) return;
    const dummy: ProjectionCortege = {
      id: randomId(),
      isNew: true,
      modelId: next_model.id,
    //   version: next_image.version,
      versionCutoff: next_image.versionCutoff,
      imageId: next_image.id,
    };
        setRows((oldRows) => [...oldRows, dummy]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [dummy.id]: { mode: GridRowModes.Edit, fieldToFocus: "model" },
        }));
    };

    const purgeClick = () => {
        setRows((oldRows) => []);
    };

    const importClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            Papa.parse(event.target.files[0], {
                header: false,
                skipEmptyLines: true,
                dynamicTyping: true,
                complete: function (results: Papa.ParseResult<any>) {
                    let newRows: ProjectionCortege[] = results.data
                        .map((line): ProjectionCortege | null => {
                            const model_alias = line[0];
                            const next_model = models.find((model) => model.alias === model_alias);
                            if (!next_model) return null;
                            const model_version = line[1];
                            const model_version_cutoff = line[2];
                            const next_image = images.find((image) => image.version === model_version && image.hw === next_model.hw && image.dev === next_model.dev);
                            if (!next_image) return null;
                            return {
                                id: randomId(),
                                modelId: next_model.id,
                // version: model_version,
                                versionCutoff: model_version_cutoff,
                                imageId: next_image.id,
                            };
                        })
                        .filter((row): row is ProjectionCortege => row !== null);
                    const seenModels = new Set<number>();
                    const hasNoDuplicates = newRows.every((row) => {
                        const oldSize = seenModels.size;
                        seenModels.add(row.modelId);
                        return oldSize !== seenModels.size;
                    });
                    if (!hasNoDuplicates) {
                        alert("Duplicate models found in import file. Please check your file and try again.");
                        return;
                    }
                    setRows((oldRows) =>
                        oldRows.map((r1) => newRows.find((r2) => r2.modelId === r1.modelId) || r1).concat(newRows.filter((r2) => !oldRows.some((r1) => r1.modelId === r2.modelId)))
                    );
                },
            });
            event.target.value = "";
        }
    };

    return (
        <GridToolbarContainer>
            <Button startIcon={<AddIcon />} onClick={addClick}>
                Add
            </Button>
            <Button startIcon={<DeleteSweepIcon />} onClick={purgeClick}>
                Purge
            </Button>
            <Button startIcon={<FileUploadIcon />} component="label">
                Import
                <input hidden accept=".csv*" type="file" onChange={importClick} />
            </Button>
            <GridToolbarExport
                startIcon={<FileDownloadIcon />}
                printOptions={{ disableToolbarButton: true }}
                csvOptions={{
                    fileName: configId || "unsaved",
                    delimiter: ",",
                    utf8WithBom: true,
                }}
            />
        </GridToolbarContainer>
    );
};

function ModelEditComponent(params: GridRenderEditCellParams) {
    const apiRef = useGridApiContext();
    const props = { ...params };
    const handleValueChange = async () => {
        await apiRef.current.setEditCellValue({
            id: props.id,
            field: "imageId",
            value: "",
        });
    };
    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
}

export const ConfigProjections: FunctionComponent<ConfigProjectionsProps> = (props: ConfigProjectionsProps) => {
    const { configId, models, images, rows, onRowsChange, loading } = props;
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        onRowsChange(rows.filter((row) => row.id !== id) || []);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            onRowsChange(rows.filter((row) => row.id !== id) || []);
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow: any = { ...newRow, isNew: false };
        onRowsChange(rows.map((row) => (row.id === newRow.id ? updatedRow : row)) || []);
        return updatedRow;
    };

    const processRowUpdateError = (error: any) => {
        console.log(error);
    };

    const configProjectionsColumns: GridColDef[] = [
        {
            field: "modelId",
            headerName: "Model",
            editable: true,
            sortable: true,
            flex: 2,
            type: "singleSelect",
            valueOptions: models.map((model) => ({ label: model.alias, value: model.id })),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.hasChanged && rows.map((row) => row.modelId).filter((id) => id === params.props.value).length > 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => ModelEditComponent(params),
        },
        {
            field: "version",
            headerName: "Version",
            editable: false,
            sortable: true,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                const target_image = images.find((image) => image.id === params.row.imageId);
                return target_image?.version;
            },
        },
        {
            field: "versionCutoff",
            headerName: "Optional",
            editable: true,
            sortable: true,
            flex: 1,
        },
        {
            field: "imageId",
            headerName: "Image",
            editable: true,
            sortable: true,
            flex: 2,
            type: "singleSelect",
            valueOptions: (params: GridValueOptionsParams) => {
                if (!params.row) {
                    // The row is not available when filtering this column
                    return [];
                }
                const target_model = models.find((model) => model.id === params.row.modelId);
                return images.filter((image) => target_model?.hw === image.hw && target_model?.dev === image.dev).map((image) => ({ label: image.filename, value: image.id }));
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value.length === 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                    ];
                }
                return [
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
                ];
            },
        },
    ];

    return (
        <configContext.Provider value={configId}>
            <modelsContext.Provider value={models}>
                <imagesContext.Provider value={images}>
                    <StyledBox>
                        <DataGrid
                            loading={loading}
                            rows={rows || []}
                            columns={configProjectionsColumns}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                            onRowEditStart={(_, event: MuiEvent) => {
                                event.defaultMuiPrevented = true;
                            }}
                            onRowEditStop={(_, event: MuiEvent) => {
                                event.defaultMuiPrevented = true;
                            }}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={processRowUpdateError}
                            slots={{
                                toolbar: ProjectionsEditToolbar,
                            }}
                            slotProps={{
                                toolbar: {
                                    rows: rows || [],
                                    setRows: (updateRows: any) => onRowsChange(updateRows(rows)),
                                    setRowModesModel,
                                    printOptions: { disableToolbarButton: true },
                                },
                            }}
                            autoPageSize
                        />
                    </StyledBox>
                </imagesContext.Provider>
            </modelsContext.Provider>
        </configContext.Provider>
    );
};
