import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { Button, TextField, Typography, Toolbar, Alert, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { ConfigFilterDevices } from "./ConfigFilterDevices";
import { ConfigFilterModels } from "./ConfigFilterModels";
import { ConfigProjections } from "./ConfigProjections";
import { ConfigsList } from "./ConfigsList";
import {
  DeviceModel,
  FilterDeviceCortege,
  FilterModelCortege,
  FwImage,
  ProjectionCortege,
  Type,
  StreamConfig,
} from "./Types";
import {
  fetchConfigs,
  fetchIncludedDevices,
  fetchExcludedDevices,
  fetchWaitingDevices,
  fetchModels,
  fetchImages,
  mintConfig,
  addConfig,
  updateConfig,
  deleteConfig,
  checkConfig,
} from "./DataManagement";
import { ConfigCommonSettings } from "./ConfigCommonSettings";

function ConfigApp() {
  React.useEffect(() => {
    document.title = "HEOS Firmware Update configuration";
  }, []);

  // user wait
  const [userWait, setUserWait] = React.useState<boolean>(false);

  // get configs
  const [configs, setConfigs] = React.useState<StreamConfig[]>([]);
  const [configsLoading, setConfigsLoading] = React.useState<boolean>(false);
  async function loadConfigs() {
    setConfigsLoading(true);
    setConfigs(await fetchConfigs());
    setConfigsLoading(false);
  }
  React.useEffect(() => {
    loadConfigs();
  }, []);

  // get models
  const [models, setModels] = React.useState<DeviceModel[]>([]);
  const [modelsLoading, setModelsLoading] = React.useState<boolean>(false);
  async function loadModels() {
    setModelsLoading(true);
    setModels(await fetchModels());
    setModelsLoading(false);
  }
  React.useEffect(() => {
    loadModels();
  }, []);

  // get images
  const [images, setImages] = React.useState<FwImage[]>([]);
  const [imagesLoading, setImagesLoading] = React.useState<boolean>(false);
  async function loadImages() {
    setImagesLoading(true);
    setImages(await fetchImages());
    setImagesLoading(false);
  }
  React.useEffect(() => {
    loadImages();
  }, []);

  const [inEdit, setInEdit] = React.useState<StreamConfig>(mintConfig);
  const [configIsNew, setConfigIsNew] = React.useState<boolean>(true);
  const [configErrors, setConfigErrors] = React.useState<String[]>([]);

  async function newConfig() {
    setUserWait(true);
    inEdit.type = Type.REGULAR;
    let errors = await checkConfig(inEdit, images);
    if (errors.length > 0) {
      setConfigErrors(errors);
    } else {
      console.log(inEdit);
      await addConfig(inEdit, images);
      setInEdit(mintConfig);
      setConfigIsNew(true)
      loadConfigs();
    }
    setUserWait(false);
  }
  async function changeConfig() {
    setUserWait(true);
    inEdit.type = Type.REGULAR;
    let errors = await checkConfig(inEdit, images);
    if (errors.length > 0) {
      setConfigErrors(errors);
    } else {
      await updateConfig(inEdit, images);
      setInEdit(mintConfig);
      setConfigIsNew(true)
      loadConfigs();
    }
    setUserWait(false);
  }
  async function removeConfig(configId: string) {
    setUserWait(true);
    await deleteConfig(configId);
    setInEdit(mintConfig);
    setConfigIsNew(true)
    loadConfigs();
    setUserWait(false);
  }

  enum DetailsTab {
    VERSIONS = "Versions",
    MODELS_INCLUDED = "Model inclusion",
    MODELS_EXCLUDED = "Model exclusion",
    DEVICES_INCLUDED = "Device inclusion",
    DEVICES_EXCLUDED = "Device exclusion",
    MAPPINGS = "Projections",
  }
  const [detailsTab, setDetailsTab] = React.useState(DetailsTab.VERSIONS);

  const preventConfigChange = () => {
    return inEdit.name.trim() === "" || userWait;
  };

  return (
    <Grid container columns={3} style={{ height: "100%" }}>
      <Grid item xs={1} style={{ display: "flex", flexDirection: "column", height: "100%" }} padding={1}>
        <Typography align="center" padding={1} variant="h6">
          Streams
        </Typography>
        <ConfigsList
          rows={configs}
          includeType={Type.REGULAR}
          loading={configsLoading}
          onSelect={(config) => {
            if (config === undefined ) {
              setInEdit(mintConfig)
              setConfigIsNew(true)
            } else {
              setInEdit(config)
              setConfigIsNew(false)
              console.log(config)
            }
          }}
          onListIncluded={async (config) => await fetchIncludedDevices(config.uid)}
          onListExcluded={async (config) => await fetchExcludedDevices(config.uid)}
          onListWaiting={async (config) => await fetchWaitingDevices(config.uid)}
          onDelete={(config) => removeConfig(config.uid)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: "flex", flexDirection: "column", height: "100%" }} padding={1}>
        <Typography align="center" padding={1} variant="h6">
          {inEdit.uid ? "Selected Stream" : "New Stream"}
        </Typography>
        <ConfigCommonSettings
          config={inEdit}
          onConfigChange={(config) => setInEdit(config)}
          enableUidEdit={false}
          enablePriority={true}
          enableStrategy={false}
          enableForce={false}
          detailsTabEnum={DetailsTab}
          detailsTab={detailsTab}
          onDetailsTabChange={(tab) => setDetailsTab(tab)}
        />
        <Box padding={1}>
          <Divider variant="inset" flexItem />
        </Box>
        <Box height="100%" display="flex" flexDirection="column">
          <Box flexGrow={1} style={{ overflow: "auto" }}>
            {detailsTab === DetailsTab.VERSIONS && (
              <Grid container columns={2} rowSpacing={3} columnSpacing={1}>
                <Grid item xs={2} sm={1}>
                  <TextField
                    label="Minimum Version"
                    value={inEdit.minimumVersion || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let updated = {
                        minimumVersion: event.target.value,
                      };
                      setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <TextField
                    label="Maximum Version"
                    value={inEdit.maximumVersion || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let updated = {
                        maximumVersion: event.target.value,
                      };
                      setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Partial rollout percentage"
                    value={inEdit.rolloutTargetPercentage ?? "100"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let updated = {
                        rolloutTargetPercentage: Number(event.target.value),
                      };
                      setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            {detailsTab === DetailsTab.MODELS_INCLUDED && (
              <Box minHeight={300} height="100%">
                <ConfigFilterModels
                  configId={inEdit.uid}
                  models={models}
                  rows={inEdit.filters.models.included}
                  onRowsChange={(newRows: FilterModelCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      filters: {
                        models: {
                          included: newRows,
                          excluded: prev.filters.models.excluded,
                        },
                        devices: prev.filters.devices,
                      },
                    }));
                  }}
                  loading={modelsLoading}
                />
              </Box>
            )}
            {detailsTab === DetailsTab.MODELS_EXCLUDED && (
              <Box minHeight={300} height="100%">
                <ConfigFilterModels
                  configId={inEdit.uid}
                  models={models}
                  rows={inEdit.filters.models.excluded}
                  onRowsChange={(newRows: FilterModelCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      filters: {
                        models: {
                          included: prev.filters.models.included,
                          excluded: newRows,
                        },
                        devices: prev.filters.devices,
                      },
                    }));
                  }}
                  loading={modelsLoading}
                />
              </Box>
            )}
            {detailsTab === DetailsTab.DEVICES_INCLUDED && (
              <Box minHeight={300} height="100%">
                <ConfigFilterDevices
                  configId={inEdit.uid}
                  rows={inEdit.filters.devices.included}
                  onRowsChange={(newRows: FilterDeviceCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      filters: {
                        models: prev.filters.models,
                        devices: {
                          included: newRows,
                          excluded: prev.filters.devices.excluded,
                        },
                      },
                    }));
                  }}
                  loading={modelsLoading}
                />
              </Box>
            )}
            {detailsTab === DetailsTab.DEVICES_EXCLUDED && (
              <Box minHeight={300} height="100%">
                <ConfigFilterDevices
                  configId={inEdit.uid}
                  rows={inEdit.filters.devices.excluded}
                  onRowsChange={(newRows: FilterDeviceCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      filters: {
                        models: prev.filters.models,
                        devices: {
                          included: prev.filters.devices.included,
                          excluded: newRows,
                        },
                      },
                    }));
                  }}
                  loading={modelsLoading}
                />
              </Box>
            )}
            {detailsTab === DetailsTab.MAPPINGS && (
              <Box minHeight={300} height="100%">
                <ConfigProjections
                  configId={inEdit.uid}
                  models={models}
                  images={images}
                  rows={inEdit.projections}
                  onRowsChange={(newRows: ProjectionCortege[]) => {
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      projections: newRows,
                    }));
                  }}
                  loading={modelsLoading || imagesLoading}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexGrow={1} flexDirection="column">
          {configErrors.length > 0 &&
            configErrors.map((err, i) => (
              <Alert severity="error" onClose={() => setConfigErrors(configErrors.filter((_, j) => j !== i))}>
                {err}
              </Alert>
            ))}
        </Box>
        <Toolbar style={{ justifyContent: "center", flexShrink: 0 }}>
          {configIsNew ? (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              disabled={preventConfigChange()}
              onClick={() => newConfig()}
            >
              Add
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<UpgradeIcon />}
              disabled={preventConfigChange()}
              onClick={() => changeConfig()}
            >
              Update
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            disabled={!inEdit.uid}
            onClick={() => removeConfig(inEdit.uid)}
          >
            Delete
          </Button>
        </Toolbar>
      </Grid>
    </Grid>
  );
}

export default ConfigApp;
